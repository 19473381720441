<template>
  <component :is="tag" :class="{ 'single-date': hideEndDate }">
    <div>
      <span class="emph">{{ event.startDate | moment("DD/MM/YYYY") }}</span>
      <span> | </span>
      <span>{{ startDayTime }}</span>
    </div>
    <div v-if="!hideEndDate">
      <span class="emph">{{ event.endDate | moment("DD/MM/YYYY") }}</span>
      <span> | </span>
      <span>{{ event.endDate | moment("HH:mm") }}</span>
    </div>
  </component>
</template>

<script>
export default {
  name: 'EventCardPeriod',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isSameDay() {
      const startMoment = this.$moment(this.event.startDate);
      const endMoment = this.$moment(this.event.endDate);

      return startMoment.year() === endMoment.year() && startMoment.dayOfYear() === endMoment.dayOfYear();
    },
    startDayTime() {
      if (this.event.allDay) {
        return this.$t('calendar.meeting-form.all-day');
      }

      const startMoment = this.$moment(this.event.startDate);
      const endMoment = this.$moment(this.event.endDate);

      if (this.isSameDay) {
        return `${startMoment.format('HH:mm')} - ${endMoment.format('HH:mm')}`;
      }

      return startMoment.format('HH:mm');
    },
    hideEndDate() {
      return !this.event.endDate || this.event.allDay || this.isSameDay;
    },
  },
};
</script>
