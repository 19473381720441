<template>
  <component :is="tag" class="single-date">
    <div>
      {{price / 100}} €
    </div>
  </component>
</template>

<script>
export default {
  name: 'TicketPrice',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    price: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isSameDay() {
      const startMoment = this.$moment(this.event.startDate);
      const endMoment = this.$moment(this.event.endDate);

      return startMoment.year() === endMoment.year() && startMoment.dayOfYear() === endMoment.dayOfYear();
    },
    startDayTime() {
      if (this.event.allDay) {
        return this.$t('calendar.meeting-form.all-day');
      }

      const startMoment = this.$moment(this.event.startDate);
      const endMoment = this.$moment(this.event.endDate);

      if (this.isSameDay) {
        return `${startMoment.format('HH:mm')} - ${endMoment.format('HH:mm')}`;
      }

      return startMoment.format('HH:mm');
    },
    hideEndDate() {
      return !this.event.endDate || this.event.allDay || this.isSameDay;
    },
  },
};
</script>
